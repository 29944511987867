const revenuesum = [
    { id: "first1", content: "First task",title: 'MTD Revenue',revenue: '',rate: '',rate_type:'up', tooltip_content: '' },
    { id: "sec2", content: "Second task",title: 'Projected Revenue FTM',revenue: '',rate: '',rate_type:'up', tooltip_content: '' },
    { id: "third3", content: "Third task",title: 'Revenue from commission',revenue: '',rate: '',rate_type:'up', tooltip_content: '' },
];
const shipment = [
    { id: "deliver", content: "First task",title: 'Delivered',revenue: '',rate: '',rate_type:'up',tooltip_content: '' },
    { id: "transit", content: "Second task",title: 'In Transit',revenue: '',rate: '',rate_type:'up',tooltip_content: '' },
    { id: "stuck", content: "Third task",title: 'Stuck',revenue: '',rate: '',rate_type:'up',tooltip_content: 'stuck' },
];

const ratecoverage = [
    { id: "activePorts", content: "First task",title: 'Active Port Pair',revenue: '',rate: '',rate_type:'up', tooltip_content: '' },
    { id: "rate", content: "Second task",title: 'Carrier per Port Pair',revenue: '',rate: '',rate_type:'up', tooltip_content: 'Carrier per Port Pair' },
    { id: "coverratio", content: "Third task",title: 'Conversion Ratio',revenue: '',rate: '',rate_type:'up', tooltip_content: '' },
];

const test = [
  {
    id: 'revenue_sum',
    name: `Revenue Summary`,
    items: revenuesum
  },
  {
    id: 'shipment',
    name: `Shipments <b>(289)</b>`,
    items: shipment
  },
  {
    id: 'rate_coverage',
    name: `Rate Coverage`,
    items: ratecoverage
  }
]

export const taskStatus = {
    revenueSum: test
};


export const customerApprvd ="CUST_APPRVD";
export const QUOTATION_EDITED="EDITED_VERIFIED"
export const MAIL_SENT="MAIL_SENT"
export const customerReject ="CUST_REJECT";
export const draft ="DRAFT";
export const REQUEST_FOR_RATE="REQUEST_FOR_RATE"
export const IN_PROGRESS="IN_PROGRESS"
export const MGR_APPRV_PENDING="MGR_APPRV_PENDING"
export const MANAGER_REJECT="MANAGER_REJECT"
export const MANAGER_APPRVD="MANAGER_APPRVD"
export const INQUIRY_REJECTED="INQUIRY_REJECTED"
export const RATE_AVAILABLE="RATE_AVAILABLE"
export const RATE_UPDATED="RATE_UPDATED"
export const VERIFIED="VERIFIED"
