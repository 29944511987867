import "nouislider/distribute/nouislider.css";
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Select from "react-select";
import { Input, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import { optionDestQuote, optionOriginQuote } from '../../../common/data/sales';
import { getAllUserDetails, getCustomersCountryData } from "../../../store/Parties/actions";
import { useDispatch } from "react-redux";
import { getAllPartiesCustomerData } from "../../../store/Parties/Customer/action";

export default function FilterCustomerComp({ isRight, toggleRightCanvas, filterDetails, setfilterDetails, applyFilterHandler, clearValueHandler }) {
    const { parties_all_employee_details, parties_city_details, parties_country_details } = useSelector((state) => state?.parties);
    const dispatch = useDispatch();

    const salesEmployeeOptions = parties_all_employee_details?.content?.map((item) => ({
        value: item?.firstName,
        label: item?.firstName + " " + item?.lastName,
        id: item?.id,
        version: item?.version
    }));

    const handleSelectGroup = useCallback((name, opt) => {
        let newObj = {
            ...filterDetails,
            [name]: opt
        }
        setfilterDetails(newObj);
    }, [filterDetails]);

    useEffect(() => {
        dispatch(getAllUserDetails())
    }, [dispatch]);

    useEffect(() => {
        if (parties_country_details && parties_country_details?.content?.length > 0) {
            handleSelectGroup('country', parties_country_details?.content[0])
        }
    }, [parties_country_details]);

    
    
    return (
        <>
            <Offcanvas
                isOpen={isRight}
                direction="end"
                toggle={toggleRightCanvas}>
                <OffcanvasHeader toggle={toggleRightCanvas}>
                    Filter
                </OffcanvasHeader>
                <OffcanvasBody>
                    <form className='h-100'>
                        <div className="fcl_filter_sidebar_wrap sales_filter_wrap d-flex flex-column h-100">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label>Customer</label>
                                        <Input
                                            value={filterDetails.customer}
                                            name='customer'
                                            onChange={(opt) => { handleSelectGroup('customer', opt.target.value) }}
                                            placeholder={'Enter Name'}
                                            classNamePrefix="select2-selection form-select"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label>Contact Name</label>
                                        <Input
                                            value={filterDetails.contactName}
                                            name='customer'
                                            onChange={(opt) => { handleSelectGroup('contactName', opt.target.value) }}
                                            placeholder={'Enter Contact Name'}
                                            classNamePrefix="select2-selection form-select"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-12">
                                    <div className="mb-3">
                                        <label>City</label>
                                        <Input
                                            type="text"
                                            name="city"
                                            list='cityList'
                                            value={filterDetails?.city?.cityName}
                                            onChange={(opt) => {
                                                handleSelectGroup('city', '')
                                                handleSelectGroup('country', '')
                                                const cityData = parties_city_details?.content?.find((city) => city.cityName === opt.target.value);
                                                if (cityData) {
                                                    dispatch(getCustomersCountryData({ cityId: cityData.id }));
                                                }
                                                handleSelectGroup('city', cityData)
                                            }
                                            }
                                            className="form-control"
                                            placeholder="City"
                                        />
                                        <datalist id="cityList">
                                            {parties_city_details && parties_city_details?.content?.map((item, i) => <option key={i} value={item.cityName} />)}
                                        </datalist>
                                    </div>
                                </div>
                                {/* <div className="col-12 col-md-12">
                                    <div className="mb-3">
                                        <label>Country</label>
                                        <Input
                                            type="text"
                                            name="country"
                                            value={filterDetails.country?.countryName}
                                            onChange={(opt) => { handleSelectGroup('country', opt) }}
                                            className="form-control"
                                            placeholder="Country"
                                            readOnly={true}
                                        />
                                    </div>
                                </div> */}
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label>Sales Employee</label>
                                        <Select
                                            value={filterDetails.sales_employee}
                                            name='sales_employee'
                                            onChange={(opt) => {
                                                handleSelectGroup('sales_employee', opt);
                                            }}
                                            options={salesEmployeeOptions}
                                            placeholder={'Select Status'}
                                            classNamePrefix="select2-selection form-select"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="btn_wrap d-flex mt-auto">
                                <button className='btn border' type='button' onClick={() => { clearValueHandler(); }}>Clear</button>
                                <button className='btn btn-primary' type='button' onClick={applyFilterHandler}>Apply Filter</button>
                            </div>
                        </div>
                    </form>
                </OffcanvasBody>
            </Offcanvas>
        </>
    )
}
