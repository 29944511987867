import React from 'react'
import { convertCurrency, totalAmtArry } from '../../../components/Common/CommonLogic';
import { cube_filled, oocl_logo, zim_logo } from '../../../assets/images';
import { useSelector } from 'react-redux';

export const CurrencyWiseTotal = ({ data, newData }) => {
   
    // console.log(mergedArray);
       
    return (
        <>
            <tr>
                <td className='title_row'><b>Total</b></td>
                <td className='title_row'><b>Origin</b></td>
                <td className='title_row'><b>Destination</b></td>
            </tr>
            {totalAmtArry(data, newData)?.map(item => (
                <tr key={item?.currency}>
                    <td>Total <b>{item?.currency}</b>:</td>
                    <td>{item?.origin || 0}</td>
                    <td>{item?.destination || 0}</td>
                </tr>
            ))}
        </>
    );
}
export default function PreviewCommonTable({ data, newData, tab }) {
    const { currency_ExchangeRate } = useSelector((state) => state.quotation);
    const { searchForm } = useSelector((state) => state.instantRate);

    const subtotalCount = () => {
        let buyValue = (data?.tariffDetails?.reduce((outeracc, outerCurrent) => {
            let sum = 0;
            if (outerCurrent?.selected) {
                sum = outerCurrent?.fclTariffBreakDowns?.reduce((inneracc, currentInner) => {
                    return inneracc + convertCurrency(currentInner, Number(currentInner.amount), currency_ExchangeRate, searchForm);
                }, 0);
            }
            return outeracc + sum;
        }, 0))
        let marginValue = (data?.tariffDetails?.reduce((outeracc, outerCurrent) => {
            let innerSum = 0;
            if (outerCurrent?.selected) {
                innerSum = outerCurrent?.fclTariffBreakDowns?.reduce((accInner, currentInner) => {
                    return accInner + convertCurrency(currentInner, currentInner?.margin_value, currency_ExchangeRate, searchForm);
                }, 0);
            }
            return outeracc + innerSum;
        }, 0))

        let newSubTotal = newData?.tariffDetails !== undefined ? newData?.tariffDetails?.reduce((accOuter, currentOuter) => {
            let innerSum = currentOuter?.fclTariffBreakDowns?.reduce((accInner, currentInner) => {
                let totalAmt = Number(currentInner.unitPerPrice || 0) * Number(currentInner.unit || 1);
                return accInner + convertCurrency(currentInner, Number(totalAmt), currency_ExchangeRate, searchForm);;
            }, 0);
            return accOuter + innerSum;
        }, 0) : 0;
        let totalNewMarginSum = newData?.tariffDetails !== undefined ? newData?.tariffDetails?.reduce((accOuter, currentOuter) => {
            let innerSum = currentOuter?.fclTariffBreakDowns?.reduce((accInner, currentInner) => {
                return accInner + convertCurrency(currentInner, currentInner?.margin_value, currency_ExchangeRate, searchForm);
            }, 0);
            return accOuter + innerSum;
        }, 0) : 0;

        let mainTotal = buyValue + marginValue + newSubTotal + totalNewMarginSum
        return Math.round(mainTotal);
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString("en-GB");
    };

    return (
        <>
            {data !== undefined &&
                <div className="preview_table_wrap">
                    <div className="preview_carrier_data d-flex justify-content-between align-items-center">
                        <p className="mb-0">
                            Carrier: <b>{data?.carrierName}</b>
                        </p>
                        <div>
                            <span> Valid From:<b> {formatDate(data?.validFrom)}</b></span> &nbsp; | &nbsp;
                            <span> Valid To:<b>{formatDate(data?.validTo)}</b></span>
                        </div>
                    </div>
                    <table>
                        <caption><p className='d-flex justify-content-between align-items-center'>Freight Charges </p></caption>
                        {/* <caption><p className='d-flex justify-content-between align-items-center'>Freight Charges <span className={`tag preffered ${data.quote_type.toLowerCase() === 'preffered' ? 'preffered' : data.quote_type.toLowerCase() === 'cheaper' ? 'cheaper' : data.quote_type.toLowerCase() === 'faster' ? 'faster' : ''}`}>{data.quote_type}</span></p></caption> */}
                        <thead>
                            <tr>
                                <th>Charge Name</th>
                                <th>Charge Basis</th>
                                <th>Curreny</th>
                                <th>Quantity</th>
                                {/* <th>Tax</th> */}
                                <th>Total Cost</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.tariffDetails?.map((inner, index) => {
                                if (inner?.selected) {
                                    return (
                                        <>

                                            <tr key={`header_${data.id}_${index}`}>
                                                <td colSpan={6} className='title_row'>{inner?.header?.split("_").join(" ") || '-'}</td>
                                            </tr>
                                            {inner?.fclTariffBreakDowns?.length !== 0 && inner?.fclTariffBreakDowns?.map((sub, index) => (
                                                <tr key={`tariff_${data.id}_${index}`}>
                                                    <td>{sub?.component + "-" + sub?.containerDetail || '-'}</td>
                                                    <td>{sub?.uomCode?.split('_').join(" ") || '-'}</td>
                                                    <td>{sub?.currencyCode || 'INR'}</td>
                                                    <td>{sub?.unit || 0}</td>
                                                    {/* <td>{sub?.taxDetail?.taxPercentage || 0}</td> */}
                                                    <td>{sub?.total_sale_cost || Number(sub?.amount || 0)}</td>
                                                    {/* <td>{sub?.total_sale_cost || (Number(sub?.amount || 0) + Number(sub?.taxDetail?.value || 0))}</td> */}
                                                </tr>
                                            ))}
                                            {newData?.tariffDetails !== undefined && newData?.tariffDetails?.find(obj => obj?.header === inner?.header)?.fclTariffBreakDowns?.map((newSub, newindex) => (
                                                <tr key={`${newData?.id}_${inner?.header}${newindex}`}>
                                                    <td>{newSub?.component?.value || '-'}</td>
                                                    <td>{newSub?.uomCode?.label || '-'}</td>
                                                    <td>{newSub?.currencyCode || 'INR'}</td>
                                                    <td>{newSub?.unit || 0}</td>
                                                    {/* <td>{newSub?.taxDetail?.taxPercentage || 0}</td> */}
                                                    <td>{newSub?.total_sale_cost || 0}</td>
                                                </tr>
                                            ))}

                                            {(inner?.fclTariffBreakDowns?.length === 0 && newData?.tariffDetails?.find(obj => obj?.header === inner?.header) === undefined) && <tr><td colSpan={6}>As Per Carrier Tariff At Actuals.</td></tr>}
                                        </>
                                    )
                                }
                            })}
                            <tr>
                                <td>
                                    <p className="title">Remarks:</p>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="details">
                                                <p>{data?.remarks || '-'}</p>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={3}>
                                    <table>
                                        <CurrencyWiseTotal data={data} newData={newData} />
                                    </table>
                                </td>
                                <td colSpan={3}>
                                    <p>Sub Total: <span>{searchForm?.quote_currency?.currencyCode} {subtotalCount()}</span></p>
                                    <p><b>Total:</b> <span className='text-primary'><b>{searchForm?.quote_currency?.currencyCode} {subtotalCount()}</b></span></p>
                                </td>
                            </tr>

                            {/* <td colSpan={6}><p>Total: <span className='text-primary'><b>₹{totalCount(subtotalCount())}</b></span></p></td> */}
                        </tfoot>
                    </table>
                </div>
            }
        </>
    )
}
