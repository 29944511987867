import React from 'react';
import { Modal } from 'reactstrap';
import Select from "react-select";
import { useSelector } from 'react-redux';

const TransferDialog = ({ isOpen, onClose, onConfirm, loading, data, message, setReason }) => {

  const { reason_master } = useSelector(state => state?.globalReducer);
  if (!isOpen) return null;



  return (
    <Modal size="md" isOpen={isOpen} onClose={onClose} className='transferDialog_modal' >
      <div className="modal-header">
        <h1 className='m-0'>Confirmation</h1>
        <button
          onClick={onClose}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className='modal-body'>
        <p>Do you wish to transfer (<span className='transfer_id'>{data}</span>)
          <span className='dark-text'> In Progress</span> to <span className='dark-text'>{message}</span></p>
        {message === "Lost" &&
          <>
            <div className="mb-1">
              <label>reason</label>
              <Select
                name="reason"
                options={reason_master}
                onChange={(reason) => setReason(reason)}
                classNamePrefix="select2-selection form-select"
              />
            </div>
          </>}
      </div>

      <div className='modal-footer'>
        <div className="btn_wrap d-flex mt-auto">
          <button className="btn border" type="button" onClick={onClose}>Cancel</button>
          <button className="btn btn-primary" type="button" onClick={onConfirm} disabled={loading}>{loading ? 'Transferring...' : 'Transfer'}</button>
        </div>
      </div>

    </Modal>
  );
};


export default TransferDialog

