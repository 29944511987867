import moment from "moment/moment";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export function removeNullProperties(obj) {
    if (obj && typeof obj === 'object') {
        for (const key in obj) {
            if (obj[key] === null || obj[key] === undefined) {
                delete obj[key];
            } else if (typeof obj[key] === 'object') {
                removeNullProperties(obj[key]);
            }
        }
    }
}


// -------------- Currency wise total
export const convertToINR = (amount, currency) => {
    // Define your conversion rate from USD to INR here
    const usdToINRConversionRate = 83; // Replace with the actual conversion rate      
    const BDTToINRConversionRate = 1.33;
    const IDRToINRConversionRate = 0.0053;
    if (currency === '$' || currency?.toLowerCase() === 'usd') {
        return amount * usdToINRConversionRate;
    }
    if (currency === 'BDT') {
        let newAmt = amount / BDTToINRConversionRate;
        return Math.round(newAmt);
    }
    if (currency === 'IDR') {
        let newAmt = amount * IDRToINRConversionRate;
        return Math.round(newAmt);
    }
    return amount;
};


export function convertCurrency(charge, amount, currency_ExchangeRate, searchForm) {
    let filterCurrency = currency_ExchangeRate?.find(data => data?.baseCurrency?.currencyCode === charge?.currencyCode && data?.targetCurrency?.currencyCode === searchForm?.quote_currency?.currencyCode);
    return filterCurrency?.value * amount || 0;
}

// ------------ Formats the Date
export const formatDate = (date) => {
    const d = new Date(date);
    return moment(d).format('ll');
}

export const calculateTimeDifference = (lastLoggedIn) => {
    const currentTime = moment();
    const targetTime = moment(lastLoggedIn);
    const diffInDays = currentTime?.diff(targetTime, 'days');
    const diffInHours = currentTime?.diff(targetTime, 'hours');
    const diffInMinutes = currentTime?.diff(targetTime, 'minutes');

    if (diffInDays === 1) {
        return 'Yesterday';
    } else if (diffInDays === 0) {
        if (diffInHours === 0 && diffInMinutes > 0) {
            return `${diffInMinutes} minutes ago`;
        } else if (diffInHours > 0) {
            return `${diffInHours} hours ago`;
        } else {
            return 'Just now';
        }
    } else {
        return targetTime.format('MMM DD, YYYY');
    }
};

/** * Formats the size */
export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}


/** * Checks if any value is empty */
export const isAnyValueEmpty = (obj, removeKey) => {
    let updatedObj = { ...obj };
    if (removeKey !== undefined && removeKey?.length > 0) {
        for (const key of removeKey) {
            delete updatedObj?.[key];
        }
    }
    for (const key in updatedObj) {
        if (Object.prototype.hasOwnProperty.call(updatedObj, key)) {
            const value = updatedObj[key];
            if (value === '' || value === null || value === undefined) {
                return true;
            }
        }
    }
    return false;
};

export const isAnyValueEmptyInArray = (arr, removeKey) => {
    for (const obj of arr) {
        let updatedObj = { ...obj };

        if (removeKey !== undefined && removeKey?.length > 0) {
            for (const key of removeKey) {
                delete updatedObj?.[key];
            }
        }

        for (const key in updatedObj) {
            if (Object.prototype.hasOwnProperty.call(updatedObj, key)) {
                const value = updatedObj[key];
                if (value === '' || value === null || value === undefined) {
                    return true;
                }
            }
        }
    }
    return false;
};

/* Custom sort function */
export function customSort(array, sortField, sortOrder) {
    return [...array].sort((a, b) => {
        if (a[sortField] === null) return 1;
        if (b[sortField] === null) return -1;
        if (a[sortField] === null && b[sortField] === null) return 0;
        return (
            a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
                numeric: true,
            }) * (sortOrder === "asc" ? 1 : -1)
        );
    });
}

export const isExcelFile = (fileName) => {
    return fileName.endsWith(".xls") || fileName.endsWith(".xlsx");
};

/* --------------------- common logic -------------------------------------------*/
export const commonFunc = (array, index, params, seArray) => {
    if (array?.length !== 0) {
        if (array.some(obj => obj.index === index)) {
            array.find(obj => obj.index === index)[params] = !array.find(obj => obj.index === index)[params]
        } else {
            let newObj = { [params]: true, index }
            array.push(newObj);
        }
    } else {
        let newObj = { [params]: true, index }
        array.push(newObj);
    }
    seArray(array);
}



// -------------------- outside click
export const handleClickOutside = (event, wrapperRef, setOpenPop) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        if (event.target.id !== 'more_menu') {
            setOpenPop(false);
        }
    }
};

export const useOutsideClick = (wrapperRef, setOpenPop) => {
    useEffect(() => {
        const handleOutsideClick = (event) =>
            handleClickOutside(event, wrapperRef, setOpenPop);

        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [wrapperRef, setOpenPop]);
};

import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { sitelogo } from "../../assets/images";


export const totalAmtArry = (data, newData) => {
    const processTariffDetails = (tariffDetails, type) => {
        const headerMapping = {
            "ORIGIN_INLAND_CHARGES": "origin",
            "ORIGIN_LOCAL_PORT_CHARGES": "origin",
            "FREIGHT_CHARGES": "origin",
            "DESTINATION_INLAND_CHARGES": "destination",
            "DESTINATION_LOCAL_PORT_CHARGES": "destination",
        };
        return tariffDetails?.reduce((result, currentOuter) => {
            if (type === 'new') {
                const newArray = currentOuter?.fclTariffBreakDowns?.reduce((accInner, currentInner) => {
                    const currentCurrency = currentInner?.currencyCode;
                    const existingObj = accInner?.find((item) => item.currency === currentCurrency);
                    if (existingObj) {
                        existingObj.amount += Number(currentInner?.total_sale_cost || currentInner?.amount);
                    } else {
                        accInner.push({ currency: currentCurrency, amount: Number(currentInner?.total_sale_cost || currentInner?.amount) });
                    }
                    return accInner;
                }, []);
                const header = headerMapping[currentOuter?.header];
                const exObj = result?.find(obj => obj.header === header);
                if (exObj) {
                    exObj.data = exObj?.data.concat(newArray);
                } else {
                    result.push({ header, data: newArray });
                }
            } else {
                if (currentOuter?.selected) {
                    const newArray = currentOuter?.fclTariffBreakDowns?.reduce((accInner, currentInner) => {
                        const currentCurrency = currentInner?.currencyCode;
                        const existingObj = accInner?.find((item) => item.currency === currentCurrency);
                        if (existingObj) {
                            existingObj.amount += Number(currentInner?.total_sale_cost || currentInner?.amount);
                        } else {
                            accInner.push({ currency: currentCurrency, amount: Number(currentInner?.total_sale_cost || currentInner?.amount) });
                        }
                        return accInner;
                    }, []);
                    const header = headerMapping[currentOuter?.header];
                    const exObj = result?.find(obj => obj.header === header);
                    if (exObj) {
                        exObj.data = exObj?.data.concat(newArray);
                    } else {
                        result.push({ header, data: newArray });
                    }
                }
            }
            return result;
        }, []).map((item) => ({
            ...item,
            data: item?.data?.reduce((acc, current) => {
                const exObj = acc?.find(obj => obj.currency === current?.currency);
                if (exObj) {
                    exObj.amount += Number(current?.amount);
                } else {
                    acc.push({ currency: current?.currency, amount: current?.amount });
                }
                return acc;
            }, [])
        })).reduce((finalResult, item) => {
            item.data.forEach((dataItem) => {
                const existingCurrency = finalResult?.find((resultItem) => resultItem.currency === dataItem.currency);
                if (existingCurrency) {
                    existingCurrency[item.header] = dataItem.amount;
                } else {
                    finalResult.push({ currency: dataItem.currency, [item.header]: dataItem.amount });
                }
            });
            return finalResult;
        }, []);
    };

    const filteredArray = processTariffDetails(data?.tariffDetails);
    const filteredArrayNew = processTariffDetails(newData?.tariffDetails, 'new');

    // console.log(filteredArray, "filteredArray");
    // console.log(filteredArrayNew, "filteredArrayNew");

    const mergedMap = new Map();
    const updateMap = (currency, origin, destination) => {
        if (mergedMap.has(currency)) {
            const existingEntry = mergedMap.get(currency);
            existingEntry.origin += origin;
            existingEntry.destination += destination;
        } else {
            mergedMap.set(currency, { currency, origin, destination });
        }
    };

    filteredArray?.forEach(({ currency, origin, destination }) => {
        updateMap(currency, origin || 0, destination || 0);
    });

    filteredArrayNew?.forEach(({ currency, origin, destination }) => {
        updateMap(currency, origin || 0, destination || 0);
    });
    // console.log(mergedMap,"mergedMap");

    // Convert the merged map values back to an array
    const mergedArray = [...mergedMap.values()];
    return mergedArray;
}

export const generatePDF = (quoteData, tenant_info, quoteSelectedTenantdata, totalAmt ,currencyCode) => {
    const doc = new jsPDF("p", "mm", "a4");
    let currentY = 10;

    doc.setFontSize(10);
    doc.text(`Issue Date: ${quoteSelectedTenantdata?.createdDate ? formatDate(quoteSelectedTenantdata?.createdDate) : new Date().toLocaleDateString()}`, 140, currentY + 5);
    doc.text(`Quotation: ${quoteSelectedTenantdata?.sequenceId || "-"}`, 140, currentY + 10);

    if (tenant_info?.logo) {
        doc.addImage(tenant_info?.logo, "PNG", 10, currentY, 30, 10);
    }

    currentY += 15;
    doc.line(10, currentY, 200, currentY);
    const customerBoxHeight = 15;
    doc.setFontSize(12);
    doc.rect(10, currentY, 190, customerBoxHeight);
    doc.text("Customer Details:", 12, currentY + 7);
    doc.setFontSize(10);
    doc.text(quoteData.tenantCustomerName || "-", 12, currentY + 12);
    currentY += customerBoxHeight + 2;

    const boxHeight = 20;
    doc.setFontSize(10);
    doc.rect(10, currentY, 90, boxHeight);
    doc.text("Origin:", 12, currentY + 5);
    doc.text(`Port: ${quoteData.originName || "-"}`, 12, currentY + 10);
    doc.text(`Detention Free (days): ${quoteData.detentionFreeOrg || "-"}`, 12, currentY + 15);

    doc.rect(110, currentY, 90, boxHeight);
    doc.text("Destination:", 112, currentY + 5);
    doc.text(`Port: ${quoteData.destinationName || "-"}`, 112, currentY + 10);
    doc.text(`Detention Free (days): ${quoteData.detentionFreeDest || "-"}`, 112, currentY + 15);

    currentY += boxHeight + 3;

    doc.line(10, currentY, 200, currentY);

    currentY += 5;
    doc.setFillColor(220, 220, 220);
    doc.rect(14, currentY, 190, 8, "F");
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text("COMMODITIES", 14, currentY + 6);
    currentY += 10;

    const commodities = quoteData.commodities || [["-", "-", "-", "-"]];
    autoTable(doc, {
        head: [["Description", "Package Type", "Gross Weight", "Volume (CBM)"]],
        body: commodities.map((item) => [
            item.description || "-",
            item.packageType || "-",
            item.grossWeight || "-",
            item.volume || "-",
        ]),
        startY: currentY,
        // theme: "grid",
        columnStyles: {
            0: { cellWidth: 50, halign: 'left' },
            1: { cellWidth: 50, halign: 'left' },
            2: { cellWidth: 45, halign: 'right' },
            3: { cellWidth: 45, halign: 'right' },
        }
    });
    currentY = doc.previousAutoTable.finalY + 5;

    doc.setFontSize(10);
    doc.text(`Carrier: ${quoteData.carrierName || "-"}`, 14, currentY);
    doc.text(`Valid From: ${quoteData.validFrom || "-"}  To: ${quoteData.validTo || "-"}`, 140, currentY);
    currentY += 3;


    autoTable(doc, {
        head: [["Charge Name", "Charge Basis", "Currency", "Quantity", "Total Cost"]],
        startY: currentY,
        body: [[]],
        columnStyles: {
            0: { cellWidth: 60, halign: 'left' },
            1: { cellWidth: 45, halign: 'center' },
            2: { cellWidth: 30, halign: 'center' },
            3: { cellWidth: 25, halign: 'right' },
            4: { cellWidth: 30, halign: 'right' },
        }
    });
    currentY += 10;
    quoteData.tariffDetails?.forEach((tariff) => {
        doc.setFillColor(240, 240, 240);
        doc.rect(14, currentY, 190, 8, "F");
        doc.setFontSize(10);
        doc.text(tariff.header?.split("_").join(" ") || "Tariff Header", 14, currentY + 6);
        currentY += 10;

        autoTable(doc, {
            head: [],
            body:
                tariff.fclTariffBreakDowns?.map((breakdown) => [
                    `${breakdown.component || "-"}-${breakdown.containerDetail || "-"}`,
                    breakdown.uomCode?.split("_").join(" ") || "-",
                    breakdown.currencyCode || "INR",
                    breakdown.unit || "0",
                    breakdown.total_sale_cost || "0",
                ]) || [["As Per Carrier Tariff At Actuals.", "-", "-", "-", "-"]],
            startY: currentY,
            columnStyles: {
                0: { cellWidth: 60, halign: 'left' },
                1: { cellWidth: 45, halign: 'center' },
                2: { cellWidth: 30, halign: 'center' },
                3: { cellWidth: 25, halign: 'right' },
                4: { cellWidth: 30, halign: 'right' },
            }
        });
        currentY = doc.previousAutoTable.finalY + 3;
    });
    doc.line(10, currentY, 200, currentY);
    doc.text("Remarks", 12, currentY + 5);
    doc.text(` ${quoteData.remarks || "-"}`, 12, currentY + 10);
    currentY += 15;
    const array = totalAmtArry(quoteData, [])

    autoTable(doc, {
        head: [["", "Origin", "Destination"]],
        body: array?.map((item) => [
            item.currency || "-",
            item.origin || "INR",
            item.destination || "0"
        ]),
        startY: currentY,
    });
    currentY = doc.previousAutoTable.finalY + 5;

    doc.setFontSize(12);
    doc.text(`Total: ${currencyCode} ${totalAmt || "0"}`, 165, currentY + 5);
    doc.save(quoteSelectedTenantdata?.inquiryDetailId + ".pdf");
};

export const generatePDFPreview2 = (quoteData, tenant_info) => {
    const doc = new jsPDF("p", "mm", "a4");
    let currentY = 10;

    // Header Section
    if (tenant_info?.logo) {
        doc.addImage(sitelogo, "PNG", 10, currentY, 30, 10);
    }
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 255); // Blue text for title
    doc.text("Quotation Document", 90, currentY + 7);
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0); // Black text for normal
    doc.text(`Date: ${new Date().toLocaleDateString()}`, 170, currentY + 5, { align: "right" });

    currentY += 15;

    // Greeting Section
    doc.setFontSize(10);
    doc.text("Dear Lokesh James Bond,", 10, currentY);
    currentY += 5;
    doc.text(
        "Thank you for your recent enquiry. tarifftales is pleased to make you the following offer,",
        10,
        currentY
    );
    currentY += 5;
    doc.text("for which please find our rates and further details below.", 10, currentY);
    currentY += 10;
    doc.text(`Offer expires on: ${quoteData.expiryDate || "N/A"}`, 10, currentY);
    currentY += 10;
    doc.text(
        "Our quotation is valid until the above mentioned offer expiry date. We reserve the right to",
        10,
        currentY
    );
    currentY += 5;
    doc.text(
        "review and re-quote, if we do not receive any booking prior to above mentioned offer expiry date.",
        10,
        currentY
    );
    currentY += 15;

    // Commodities Table
    doc.setFillColor(220, 220, 220); // Light grey background
    doc.rect(14, currentY, 190, 8, "F");
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0); // Reset text color
    doc.text("COMMODITIES", 14, currentY + 6);
    currentY += 10;

    const commodities = quoteData.commodities || [["-", "-", "-", "-"]];
    autoTable(doc, {
        head: [["Description", "Package Type", "Gross Weight", "Volume (CBM)"]],
        body: commodities.map((item) => [
            item.description || "-",
            item.packageType || "-",
            item.grossWeight || "-",
            item.volume || "-",
        ]),
        startY: currentY,
        // theme: "grid",
        columnStyles: {
            0: { cellWidth: 50, halign: 'left' },
            1: { cellWidth: 50, halign: 'left' },
            2: { cellWidth: 45, halign: 'right' },
            3: { cellWidth: 45, halign: 'right' },
        }
    });
    currentY = doc.previousAutoTable.finalY + 5;

    // Tariff Details
    autoTable(doc, {
        head: [["Charge Name", "Charge Basis", "Currency", "Quantity", "Total Cost"]],
        startY: currentY,
        // theme: "grid",
        body: [[]],
        columnStyles: {
            0: { cellWidth: 60, halign: 'left' },
            1: { cellWidth: 45, halign: 'center' },
            2: { cellWidth: 30, halign: 'center' },
            3: { cellWidth: 25, halign: 'right' },
            4: { cellWidth: 30, halign: 'right' },
        },
    });
    currentY += 10;

    quoteData.tariffDetails?.forEach((tariff) => {
        doc.setFillColor(240, 240, 240); // Light grey background
        doc.rect(14, currentY, 190, 8, "F");
        doc.setFontSize(10);
        doc.text(tariff.header?.split("_").join(" ") || "Tariff Header", 14, currentY + 6);
        currentY += 10;

        autoTable(doc, {
            head: [],
            body:
                tariff.fclTariffBreakDowns?.map((breakdown) => [
                    `${breakdown.component || "-"}-${breakdown.containerDetail || "-"}`,
                    breakdown.uomCode?.split("_").join(" ") || "-",
                    breakdown.currencyCode || "INR",
                    breakdown.unit || "0",
                    breakdown.total_sale_cost || "0",
                ]) || [["As Per Carrier Tariff At Actuals.", "-", "-", "-", "-"]],
            startY: currentY,
            // theme: "grid",
            columnStyles: {
                0: { cellWidth: 60, halign: 'left' },
                1: { cellWidth: 45, halign: 'center' },
                2: { cellWidth: 30, halign: 'center' },
                3: { cellWidth: 25, halign: 'right' },
                4: { cellWidth: 30, halign: 'right' },
            },
        });
        currentY = doc.previousAutoTable.finalY + 3;
    });

    doc.setFontSize(10);
    doc.setFillColor(220, 220, 220);
    doc.rect(14, currentY, 190, 8, "F");
    doc.text("TOTAL SUMMARY", 14, currentY + 6);
    currentY += 10;

    const totals = [
        ["Total BDT", quoteData.totalBDT?.origin || "0", quoteData.totalBDT?.destination || "0"],
        ["Total INR", quoteData.totalINR?.origin || "0", quoteData.totalINR?.destination || "0"],
        ["Total USD", quoteData.totalUSD?.origin || "0", quoteData.totalUSD?.destination || "0"],
    ];

    autoTable(doc, {
        head: [["", "Origin", "Destination"]],
        body: totals,
        startY: currentY,
        // theme: "grid",
        columnStyles: {
            0: { cellWidth: 90, halign: 'left' },
            1: { cellWidth: 50, halign: 'right' },
            2: { cellWidth: 50, halign: 'right' },
        }
    });
    currentY = doc.previousAutoTable.finalY + 8;

    // Sub Total and Final Total
    doc.setFontSize(12);
    doc.text(`Sub Total: ${quoteData.subTotal || "N/A"}`, 140, currentY);
    doc.text(`Total: ${quoteData.totalCost || "N/A"}`, 140, currentY + 5);

    // Save PDF
    doc.save("quotation.pdf");
};

