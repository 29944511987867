export const UPDATE_QUOTE_MODAL_CHARGES = "UPDATE_QUOTE_MODAL_CHARGES";
export const ADD_QUOTE_MODAL_CHARGES = "ADD_QUOTE_MODAL_CHARGES";
export const REMOVE_QUOTE_MODAL_CHARGES = "REMOVE_QUOTE_MODAL_CHARGES";
export const BLANK_MODAL_CHARGE = "BLANK_MODAL_CHARGE";
export const GET_CURRENCY_EXCHANGE_RATE = "GET_CURRENCY_EXCHANGE_RATE";
export const GET_CURRENCY_EXCHANGE_RATE_SUCCESS = "GET_CURRENCY_EXCHANGE_RATE_SUCCESS";

export const GET_TENANT_FCL_QUOTATION_SUMMERY = 'GET_TENANT_FCL_QUOTATION_SUMMERY';
export const GET_TENANT_FCL_QUOTATION_SUMMERY_SUCCESS = 'GET_TENANT_FCL_QUOTATION_SUMMERY_SUCCESS';

export const POST_TENANT_FCL_QUOTATION_SUMMERY = 'POST_TENANT_FCL_QUOTATION_SUMMERY';
export const POST_TENANT_FCL_QUOTATION_SUMMERY_SUCCESS = 'POST_TENANT_FCL_QUOTATION_SUMMERY_SUCCESS';

export const GET_TENANT_FCL_QUOTATION_SUMMERY_BY_ID = 'GET_TENANT_FCL_QUOTATION_SUMMERY_BY_ID';


export const POST_TRANSFER_STATUS = 'POST_TRANSFER_STATUS';
export const POST_TRANSFER_STATUS_IS_LODING = 'POST_TRANSFER_STATUS_IS_LODING';
export const POST_TRANSFER_STATUS_IS_SUCCESS_LODING = 'POST_TRANSFER_STATUS_IS_SUCCESS_LODING';

export const UPDATE_QUOTION_STATUS = 'UPDATE_QUOTION_STATUS';

export const UPDATE_QUOTION_ASSIGN_TO = 'UPDATE_QUOTION_ASSIGN_TO';

