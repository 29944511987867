import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { salesEnquiryData } from '../../../common/data/dashboard'
import { inquiryBreadcrumb } from '../../../common/data/sales'
import TfBreadcrumbs from '../../../components/Common/TfBreadcrumbs'
import { getInquirySummeryData, getSalesInquiryData } from '../../../store/Sales/actions'
import { QueriesColVal } from "../partials/SalesCol";
import SalesCommonTable from '../partials/SalesCommonTable';
import FilterSalesInquiryComp from '../partials/FilterSalesInquiryComp';
import { FILTER_INQUIRY_DATA, GET_INQUIRY_DATA_SUCCESS } from '../../../store/Sales/actiontype';
import { RATE_AVAILABLE, RATE_UPDATED } from '../../Dashboard/Partials/initialData'
import { upload_icon } from '../../../assets/images'
import { useNavigate } from 'react-router-dom'
import { getInstantSearchByJsonResponceAction } from '../../../store/InstantRate/actions'
export default function QueriesComp() {
    document.title = "Inquiry || Navigating Freight Costs with Precision||Ultimate Rate Management platform"

    const { inquiry_data_loader, inquiry_data } = useSelector((state) => state?.sales);
    const [isRight, setIsRight] = useState(false);
    const { inquiry_summary_data } = useSelector((state) => state?.sales);
    const [currentPage, setCurrentPage] = useState(0);
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const inputArr = {
        org_port: '',
        dest_port: '',
        status: '',
        valid_from: '',
        valid_to: '',
        customer: '',
        sales_employee: '',
        isExcelGenerated: false
    }
    const [filterDetails, setfilterDetails] = useState(inputArr);

    const toggleRightCanvas = () => {
        setIsRight(!isRight);
    };

    const constructUrl = (page, filters) => {
        let url = `?page=${page}&size=${filterDetails?.isExcelGenerated ? inquiry_data?.totalElements : 10}&`;
        url += `${filters?.customer?.id ? `customers=${filters?.customer.id}&` : ''}`;
        url += `${filters?.org_port?.id ? `origins=${filters?.org_port.id}&` : ''}`;
        url += `${filters?.dest_port?.id ? `destinations=${filters?.dest_port.id}&` : ''}`;
        url += `${filters?.sales_employee?.id ? `salesEmployees=${filters?.sales_employee.id}&` : ''}`;
        url += `${filters?.valid_from ? `validFrom=${filters?.valid_from}&` : ''}`;
        url += `${filters?.valid_to ? `validTo=${filters?.valid_to}&` : ''}`;
        url += `${filters?.status?.id ? `status=${filters?.status?.id}&` : ''}`;
        url += `${filters?.container_type?.id ? `containerType=${filters?.container_type?.id}&` : ''}`;
        url += `${filterDetails?.isExcelGenerated ? `isExcelGenerated=${true}&` : ''}`;
        url += 'requestForRates=false&';
        return url.substring(0, url.length - 1); // Remove trailing '&'
    };

    const applyFilterHandler = () => {
        setIsRight(false);
        filterDetails.isExcelGenerated = false;
        let url = constructUrl(currentPage, filterDetails);
        dispatch(getSalesInquiryData(url));
    };

    const clearValueHandler = () => {
        setfilterDetails(inputArr);
        let url = constructUrl(currentPage, {});
        dispatch(getSalesInquiryData(url));
    };
    useEffect(() => {
        let url = constructUrl(currentPage, filterDetails);
        dispatch(getSalesInquiryData(url));
    }, [currentPage]);

    const onClickQuoteNow = (data) => {
        navigate(`/instant-rate/search-rate`, { state: { data: data }, })
    }

    const downLoadExcelReport = () => {
        filterDetails.isExcelGenerated = true;
        let url = constructUrl(currentPage, filterDetails);
        dispatch(getSalesInquiryData(url));
    }


    const columns = useMemo(() => [
        {
            Header: 'Inquiry ID',
            accessor: 'inquiryId',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <QueriesColVal cellProps={cellProps} />
            }
        },
        {
            Header: 'Inquiry Dt',
            accessor: 'inquiryDate',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <QueriesColVal cellProps={cellProps} />
            }
        },
        {
            Header: 'Customer Name',
            accessor: 'customerName',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <QueriesColVal cellProps={cellProps} />
            }
        },
        // {
        //     Header: 'Customer Contact No',
        //     accessor: 'customerContactNo',
        //     filterable: true,
        //     disableFilters: true,
        //     Cell: (cellProps) => {
        //         return <QueriesColVal cellProps={cellProps} />
        //     }
        // },
        {
            Header: 'Origin',
            accessor: 'origin',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <QueriesColVal cellProps={cellProps} />
            }
        },
        {
            Header: 'Destination',
            accessor: 'destination',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <QueriesColVal cellProps={cellProps} />
            }
        },
        {
            Header: 'Cargo Ready Date',
            accessor: 'cargoDate',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <QueriesColVal cellProps={cellProps} />
            }
        },
        {
            Header: 'Cargo Type',
            accessor: 'cargoType',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <QueriesColVal cellProps={cellProps} />
            }
        },
        {
            Header: 'Container type',
            accessor: 'containerTypes',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <QueriesColVal cellProps={cellProps} />
            }
        },
        {
            Header: 'Weight',
            accessor: 'weight',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <QueriesColVal cellProps={cellProps} />
            }
        },
        {
            Header: 'Cargo Value',
            accessor: 'cargoValue',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <QueriesColVal cellProps={cellProps} />
            }
        },
        {
            Header: 'Incoterms',
            accessor: 'incoterm',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <QueriesColVal cellProps={cellProps} />
            }
        },
        {
            Header: 'Sales Emp',
            accessor: 'salesEmp',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <QueriesColVal cellProps={cellProps} />
            }
        },
        {
            Header: 'Status',
            accessor: 'statusCode',
            filterable: true,
            disableFilters: true,
            Cell: (cellProps) => {
                return <QueriesColVal cellProps={cellProps} />
            }
        },
        {
            Header: 'Action',
            Cell: (cellProps) => {
                const assignedBy = cellProps.row.original?.statusCode == RATE_UPDATED || cellProps.row.original?.statusCode == RATE_AVAILABLE;
                return (
                    <UncontrolledDropdown>
                        <DropdownToggle className="btn btn-link text-muted py-1 font-size-16 shadow-none" tag="a">
                            <i className='bx bx-dots-vertical-rounded'></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem className={!assignedBy ? "disabled-item" : ""} disabled={!assignedBy} onClick={(e) => { e.stopPropagation(); onClickQuoteNow(cellProps.row.original) }}>Quote Now <img src={upload_icon} alt="Add" /></DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                )
            }
        }
    ], []);

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <div className="main_freight_wrapper">
                        <div className="tf_top_breadcrumb_rate_wrap">
                            <TfBreadcrumbs breadcrumb={inquiryBreadcrumb} />
                            <div className="tf_box_wrap d-flex">
                                <div className="sh_box flex-grow-1" >
                                    <p className="box_title">Total Inquires</p>
                                    <div className="sh_inquiry_rate">{inquiry_summary_data?.totalCount}
                                        {inquiry_summary_data?.actionedCount !== undefined ? (
                                            <span className={`${inquiry_summary_data?.rate_type === 'down' ? 'red_text' : 'green_text'}`}>{inquiry_summary_data?.totalCountPercentage}%</span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="sh_box flex-grow-1" >
                                    <p className="box_title">Inquires Actioned</p>
                                    <div className="sh_inquiry_rate">{inquiry_summary_data?.actionedCount}
                                        {inquiry_summary_data?.compare_rate !== '' ? (
                                            <span className={`${inquiry_summary_data?.rate_type === 'down' ? 'red_text' : 'green_text'}`}>{inquiry_summary_data?.actionedCountPercentage}%</span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="sh_box flex-grow-1" >
                                    <p className="box_title">Pending Inquires</p>
                                    <div className="sh_inquiry_rate">{inquiry_summary_data?.pendingCount}
                                        {inquiry_summary_data?.compare_rate !== '' ? (
                                            <span className={`${inquiry_summary_data?.rate_type === 'down' ? 'red_text' : 'green_text'}`}>{inquiry_summary_data?.pendingCountPercentage}%</span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="sh_box flex-grow-1" >
                                    <p className="box_title">SLA breached</p>
                                    <div className="sh_inquiry_rate">{inquiry_summary_data?.slaBreachedCount}
                                        <span className={`${inquiry_summary_data?.rate_type === 'down' ? 'red_text' : 'green_text'}`}>{inquiry_summary_data?.slaBreachedCountPercentage}%</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <SalesCommonTable
                            columns={columns}
                            data={!!inquiry_data.content ? inquiry_data.content : []}
                            isGlobalFilter={true}
                            isAddInvoiceList={true}
                            loader={inquiry_data_loader}
                            customPageSize={10}
                            toggleRightCanvas={toggleRightCanvas}
                            downLoadExcelReport={downLoadExcelReport}
                            component={'inquiry'}
                            setCurrentPage={setCurrentPage}
                            totalPages={inquiry_data?.totalPages || 0}
                            totalEntries={inquiry_data?.totalElements || 0}
                        />
                    </div>
                </Container>
            </div>

            <FilterSalesInquiryComp isRight={isRight} toggleRightCanvas={toggleRightCanvas} filterDetails={filterDetails} setfilterDetails={setfilterDetails} applyFilterHandler={applyFilterHandler} clearValueHandler={clearValueHandler} />
        </>
    )
}
