import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Modal } from 'reactstrap';
import { updateQuotionAssingToData, updateQuotionStatusData } from '../../../../store/Sales/actions';
import { showErrorToast } from '../../../../components/Common/CustomToast';
import { INQUIRY_REJECTED, MANAGER_APPRVD, MANAGER_REJECT, REQUEST_FOR_RATE } from '../../../Dashboard/Partials/initialData';
import Select from "react-select";

const ApproveRejectPopUp = ({ isOpen, onClose, selectedRows, data, remark,seletedInquiry }) => {
    if (!isOpen) return null;
    const { status_master } = useSelector(state => state?.globalReducer);
    const { instantInquiryId } = useSelector((state) => state.instantRate);
    const { reason_master } = useSelector(state => state?.globalReducer);

    const [remarks, setRemarks] = useState(remark);
    const dispatch = useDispatch();
    const [reason, setReason] = useState("")
    const onClickSave = () => {
        if (data == "Reason For Reject Inquiry") {
            if (reason?.id) {
                let paload = {
                    statusId: status_master?.find(status => String(status?.statusCode) === INQUIRY_REJECTED).id,
                    reasonId: reason?.id,
                    inquiryId: seletedInquiry
                }
                dispatch(updateQuotionStatusData(paload))
                onClose();
            } else {
                showErrorToast('Please select reason');
            }
        }
        else if (!remarks.trim()) {
            showErrorToast('Please enter remarks');
        }
        else {
            if (data == "Request For Rate") {
                let paload = {
                    statusId: status_master?.find(status => String(status?.statusCode) === REQUEST_FOR_RATE).id,
                    remarks: remarks.trim(),
                    inquiryId: instantInquiryId,
                }
                dispatch(updateQuotionStatusData(paload))
                onClose();
            } else if (data == 'Approve' || data == 'Reject') {
                let paload = {
                    remarks: remarks.trim(),
                    statusId: data == 'Approve' ? status_master?.find(status => String(status?.statusCode) === MANAGER_APPRVD).id : status_master?.find(status => String(status?.statusCode) === MANAGER_REJECT).id,
                    quotationsIds: selectedRows
                }
                dispatch(updateQuotionAssingToData(paload));
                onClose();
            }
        }
    };

    return (
        <Modal size="md" isOpen={isOpen} onClose={onClose} className="remarks">
            <div className="modal-header">
                <h1 className="m-0">{data}</h1>
                <button
                    onClick={onClose}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                {data != "Reason For Reject Inquiry" && <textarea
                    className="form-control"
                    rows="4"
                    placeholder="Enter remarks here..."
                    value={remarks}
                    onChange={(e) => setRemarks(e.target.value)}
                />}
                {data == "Reason For Reject Inquiry" &&
                    <div>
                        <label>Reason</label>
                        <Select
                            name="reason"
                            options={reason_master}
                            value={reason}
                            onChange={(reason) => setReason(reason)}
                            classNamePrefix="select2-selection form-select"
                        />
                    </div>}
            </div>
            {data != "View Remarks" && <div className="modal-footer">
                <div className="btn_wrap d-flex mt-auto">
                    <button className="btn border" type="button" onClick={onClose}>
                        Cancel
                    </button>
                    <button className="btn btn-primary" type="button" onClick={onClickSave}>
                        Save
                    </button>
                </div>
            </div>}
        </Modal>
    );
};

export default ApproveRejectPopUp;
