import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React, { useEffect, useState, version } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'reactstrap';
import { edit_icon, preview1, preview2, sitelogo } from '../../../assets/images';
import { convertCurrency, formatDate, generatePDF, generatePDFPreview2 } from '../../../components/Common/CommonLogic';
import PreviewCommonTable from './PreviewCommonTable';
import PreviewFormat1 from './PreviewFormat1';
import PreviewFormat2 from './PreviewFormat2';
import { postTenantQuotationSummeryDetails } from '../../../store/Sales/actions';
import { useDispatch } from 'react-redux';
import { draft, MGR_APPRV_PENDING, VERIFIED } from '../../Dashboard/Partials/initialData';

export default function PreviewQuotationModal({ previewModal, previewModalHand, setPreviewModal, QuoteModalHandler, isDraftDisplay = true, isSingleData, quotationData }) {
    const [previewFormat, setPreviewFormat] = useState('1');
    const [customerInfo, setCustomerInfo] = useState();
    const quoteData = useSelector((state) => state.instantRate.quote_selected_data);
    const quoteSelectedTenantdata = useSelector((state) => state.instantRate.quote_selected_tenantdata);
    const { searchForm, $instantActiveTab, instantInquiryId } = useSelector((state) => state?.instantRate);
    const { customer_data } = useSelector((state) => state?.customer);
    const mainChargeObj = useSelector((state) => state?.quotation?.mainChargeObj);
    const dispatch = useDispatch();
    const [isManagerApproval, setIsManagerApproval] = useState(false);
    const { status_master, reason_master, oceanPort_data, vendor_data, UOM_data, currency_data, container_data } = useSelector(state => state?.globalReducer);
    const { login_user_data } = useSelector((state) => state.Login);
    const { currency_ExchangeRate } = useSelector((state) => state.quotation);
    const { tenant_info } = useSelector((state) => state?.settings);

    useEffect(() => {
        let data = customer_data && customer_data?.content?.find(obj => obj.id === searchForm?.customerName?.value);
        if (data) {
            setCustomerInfo(data);
        } else {
            setCustomerInfo({ name: quoteSelectedTenantdata?.contactName, address: "" })
        }
        quoteData?.forEach(item => {
            setIsManagerApproval(overAllMarginHandler(item, subTotalHandler(item)) < login_user_data?.discountPurview)
        })
        console.log(isManagerApproval);

    }, [customer_data, quoteData]);


    console.log(quoteData);
    

    const confirmHandler = (conform) => {
        let historyData = quoteData.map(data => {
            return {
                statusTime: new Date().toTimeString().split(' ')[0],
                statusDate: new Date().toISOString().split('T')[0],
                reasonMasterId: reason_master?.find(reason => String(reason?.reasonCode) === "INVALID")?.id,
                statusMasterId: conform ? status_master?.find(status => String(status?.statusCode) === VERIFIED)?.id : isManagerApproval ? status_master?.find(status => String(status?.statusCode) === MGR_APPRV_PENDING)?.id : status_master?.find(status => String(status?.statusCode) === draft)?.id,
            };
        });
        let addedCharges = mainChargeObj.map(data => {
            return {
                tenantFclQuotationsCarrierCharges: data.tariffDetails.flatMap(charge =>
                    charge.fclTariffBreakDowns.map(breakDown => {
                        return {
                            currency: currency_data?.find(currency => String(currency.currencyCode) === String(breakDown?.currencyCode)),
                            chargeHeader: charge?.header,
                            oceanContainer: container_data?.find(container => String(container?.value) === String(breakDown?.containerDetail)),
                            unitOfMeasurement: UOM_data?.find(uom => String(uom?.value) === String(breakDown?.value)),
                            unitCost: breakDown?.unit,
                            totalBuyCost: breakDown?.total_sale_cost,
                            chargeName: breakDown?.component?.surchargeCategory,
                            markupType: breakDown?.markup_type || "PERCENTAGE",
                            markupValue: breakDown?.markup_val,
                            chargeMarginAmount: breakDown?.margin_value
                        };
                    })
                )
            };
        });
        let data = {
            tenantCustomerId: searchForm?.customerName?.value,
            contactName: customerInfo?.name,
            contactNo: customerInfo?.contactNo,
            inquiryDetailId: instantInquiryId,
            contactEmail: customerInfo?.contactEmail,
            statusMasterId: conform ? status_master?.find(status => String(status?.statusCode) === VERIFIED)?.id : isManagerApproval ? status_master?.find(status => String(status?.statusCode) === MGR_APPRV_PENDING)?.id : status_master?.find(status => String(status?.statusCode) === draft)?.id,
            tenantFclQuotationStatusHistories: historyData,
            tenantFclQuotationCarrierDetails: quoteData.map((data, index) => {
                const vendor = vendor_data?.content?.find(vendor => Number(vendor.id) === Number(data?.carrierId));
                const originalCharges = data.tariffDetails.flatMap(charge =>
                    charge.fclTariffBreakDowns.map(breakDown => {
                        return {
                            currency: currency_data?.find(currency => String(currency.currencyCode) === String(breakDown?.currencyCode)),
                            chargeHeader: charge?.header,
                            tenantOceanFCLFreightRPId: charge?.chargeId,
                            oceanContainer: container_data?.find(container => String(container?.value) === String(breakDown?.containerDetail)),
                            unitOfMeasurement: UOM_data?.find(uom => String(uom?.value) === String(breakDown?.uomCode)),
                            unitCost: breakDown?.unit,
                            totalBuyCost: breakDown?.unitPerPrice,
                            chargeName: breakDown?.component,
                            markupType: breakDown?.markup_type || "PERCENTAGE",
                            markupValue: breakDown?.markup_val || 10,
                            chargeMarginAmount: breakDown?.margin_value,
                            totalSaleCost: breakDown?.total_sale_cost || breakDown?.amount,
                        };
                    })
                );
                const mergedCharges = [
                    ...originalCharges,
                    ...(addedCharges[index]?.tenantFclQuotationsCarrierCharges || [])
                ];

                return {
                    tenantCarrier: vendor,
                    tenantVendorId: vendor?.id,
                    remarks: data?.remarks,
                    destinationPort: oceanPort_data?.find(ocean => String(ocean.id) === String(searchForm?.location_to?.value)),
                    originPort: oceanPort_data?.find(ocean => String(ocean.id) === String(searchForm?.location_from?.value)),
                    originCityId: vendor?.city?.id,
                    destinationCityId: vendor?.city?.id,
                    tenantFclQuotationsCarrierCharges: mergedCharges,
                    grossMargin: overAllMarginHandler(data, subTotalHandler(data)),
                    originLocationType: data?.originLocationType,
                    destinationLocationType: data?.destinationLocationType,
                    transitTime: data?.oceanTransitTime,
                    detentionFreeOrg: data?.detentionFreeOrg,
                    detentionFreeDest: data?.detentionFreeDest,
                    viaPorts: data?.viaPorts?.map(item => item?.id).filter(id => id !== undefined).join(','),
                    quotationValidFrom: data?.validFrom,
                    quotationValidTo: data?.validTo,
                    quotationValue: subTotalHandler(data),
                };
            })
        };
        dispatch(postTenantQuotationSummeryDetails(data))
        setPreviewModal(false);
    }

    const onClickVerifyandDownLoad = () => {
        // const componentToCapture = document.getElementById('component-to-capture');

        // const images = Array.from(componentToCapture.getElementsByTagName('img'));
        // const imagePromises = images.map(img => {
        //     return new Promise((resolve) => {
        //         if (img.complete) resolve();
        //         else img.onload = resolve;
        //     });
        // });

        // Promise.all(imagePromises).then(() => {
        //     html2canvas(componentToCapture, { useCORS: true }).then((canvas) => {
        //         const pdf = new jsPDF('p', 'mm', 'a4');
        //         pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, 210, 297);
        //         pdf.save(instantInquiryId + '.pdf');
        //     });
        // });
        generatePDF(quoteData[0],tenant_info,quoteSelectedTenantdata, subTotalHandler(quoteData[0]),searchForm?.quote_currency?.currencyCode);
        confirmHandler(true);
    };

    console.log(quoteData);

    const handleDownloadPDF = () => {
        if (!isDraftDisplay) {
          //  window.print();
          generatePDF(quoteData[0],tenant_info,quoteSelectedTenantdata, subTotalHandler(quoteData[0]),searchForm?.quote_currency?.currencyCode);
            // const componentToCapture = document.getElementById('component-to-capture');

            // const images = Array.from(componentToCapture.getElementsByTagName('img'));
            // const imagePromises = images.map(img => {
            //     return new Promise((resolve) => {
            //         if (img.complete) resolve();
            //         else img.onload = resolve;
            //     });
            // });

            // Promise.all(imagePromises).then(() => {
            //     html2canvas(componentToCapture, { useCORS: true }).then((canvas) => {
            //         const pdf = new jsPDF('p', 'mm', 'a4');
            //         pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, 210, 297);
            //         pdf.save(quoteSelectedTenantdata?.inquiryDetailId + '.pdf');
            //     });
            // });
        } else {
            confirmHandler(false);
        }
    };
    const subTotalHandler = (quoteObject) => {
        let mainChargeCurr = mainChargeObj?.find(obj => obj.id === quoteObject.quote_id) || [];

        const totalSum = quoteObject?.tariffDetails?.reduce((accOuter, currentOuter) => {
            let innerSum = 0;
            if (currentOuter?.selected) {
                innerSum = currentOuter?.fclTariffBreakDowns?.reduce((accInner, currentInner) => {
                    return accInner + convertCurrency(currentInner, Number(currentInner.amount), currency_ExchangeRate, searchForm);
                }, 0);
            }
            return accOuter + innerSum;
        }, 0);
        const totalMarginSum = quoteObject?.tariffDetails?.reduce((accOuter, currentOuter) => {
            let innerSum = 0;
            if (currentOuter?.selected) {
                innerSum = currentOuter?.fclTariffBreakDowns?.reduce((accInner, currentInner) => {
                    return accInner + convertCurrency(currentInner, currentInner?.margin_value, currency_ExchangeRate, searchForm);
                }, 0);
            }
            return accOuter + innerSum;
        }, 0);

        const newSubTotal = mainChargeCurr?.tariffDetails !== undefined ? mainChargeCurr?.tariffDetails?.reduce((accOuter, currentOuter) => {
            let innerSum = currentOuter?.fclTariffBreakDowns?.reduce((accInner, currentInner) => {
                let totalAmt = Number(currentInner.unitPerPrice || 0) * Number(currentInner.unit || 1);
                return accInner + convertCurrency(currentInner, Number(totalAmt), currency_ExchangeRate, searchForm);;
            }, 0);
            return accOuter + innerSum;
        }, 0) : 0;

        const totalNewMarginSum = mainChargeCurr?.tariffDetails !== undefined ? mainChargeCurr?.tariffDetails?.reduce((accOuter, currentOuter) => {
            let innerSum = currentOuter?.fclTariffBreakDowns?.reduce((accInner, currentInner) => {
                return accInner + convertCurrency(currentInner, currentInner?.margin_value, currency_ExchangeRate, searchForm);
            }, 0);
            return accOuter + innerSum;
        }, 0) : 0;
        let mainTotal = totalSum + newSubTotal + totalMarginSum + totalNewMarginSum;
        return Math.ceil(mainTotal);
    }

    const overAllMarginHandler = (quoteObject, subtotalvalue) => {
        let mainChargeCurr = mainChargeObj?.find(obj => obj.id === quoteObject.quote_id) || [];

        const totalMarginSum = quoteObject?.tariffDetails?.reduce((accOuter, currentOuter) => {
            let innerSum = 0;
            if (currentOuter?.selected) {
                innerSum = currentOuter?.fclTariffBreakDowns?.reduce((accInner, currentInner) => {
                    return accInner + convertCurrency(currentInner, currentInner?.margin_value, currency_ExchangeRate, searchForm);
                }, 0);
            }
            return accOuter + innerSum;
        }, 0);

        const totalNewMarginSum = mainChargeCurr?.tariffDetails !== undefined ? mainChargeCurr?.tariffDetails?.reduce((accOuter, currentOuter) => {
            let innerSum = currentOuter?.fclTariffBreakDowns?.reduce((accInner, currentInner) => {
                return accInner + convertCurrency(currentInner, currentInner?.margin_value, currency_ExchangeRate, searchForm);
            }, 0);
            return accOuter + innerSum;
        }, 0) : 0;


        let totalMargin = totalMarginSum + totalNewMarginSum;
        let buyvalue = subtotalvalue - totalMargin

        return (totalMargin * 100 / buyvalue).toFixed(2)
    }

    return (
        <>
            <Modal size="md" isOpen={previewModal} toggle={() => { previewModalHand(); }} className='preview_modal_wrap'>
                <div className="modal-header modal-left-button">
                    <button type="button">Change Format</button>
                    <div className="format_image_wrap">
                        <div className={`img ${previewFormat === '1' ? 'active' : ''}`} onClick={() => { setPreviewFormat('1') }}><img src={preview1} alt="Preview" /></div>
                        <div className={`img ${previewFormat === '2' ? 'active' : ''}`} onClick={() => { setPreviewFormat('2') }}><img src={preview2} alt="Preview" /></div>
                    </div>
                </div>
                <div className="modal-header">
                    <button type="button" onClick={() => { setPreviewModal(false); }}><i className='bx bx-plus me-2'></i> Close</button>
                    {isDraftDisplay &&
                        <>
                            <button type="button" onClick={() => { setPreviewModal(false); QuoteModalHandler("edit") }}><img src={edit_icon} alt="Edit" className='me-2' /> Edit</button>
                            <button type="button" onClick={() => { handleDownloadPDF(); }}><i className='bx bx-check-circle me-2'></i> {isManagerApproval ? 'Request For Approval' : "Draft"}</button>
                            <button type="button" onClick={() => { onClickVerifyandDownLoad(); }}><i className='bx bx-download me-2'></i> Confirm </button>
                        </>
                    }
                    {!isDraftDisplay && quotationData?.statusCode != draft &&
                        <>
                            <button type="button" onClick={() => { handleDownloadPDF(); }}><i className='bx bx-check-circle me-2'></i> Download</button>
                        </>
                    }

                </div>
                <div id="component-to-capture" className="modal-body">
                    {previewFormat === '2' ? <PreviewFormat2 customerInfo={customerInfo} isSingleData={isSingleData} /> : <PreviewFormat1 customerInfo={customerInfo} isSingleData={isSingleData} />}
                </div>
            </Modal>
        </>
    )
}
