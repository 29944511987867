import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import { GET_DASHBOARD_INQUIRY_SUMMARY_DATA, GET_DASHBOARD_INQUIRY_SUMMARY_DATA_SUCCESS, GET_INQUIRY_CUSTOMER_SUMMARY_DATA, GET_INQUIRY_CUSTOMER_SUMMARY_DATA_SUCCESS, GET_INQUIRY_DATA, GET_INQUIRY_DATA_FAIL, GET_INQUIRY_DATA_LOADER, GET_INQUIRY_DATA_SUCCESS, GET_INQUIRY_EXPORT_SUMMARY_DATA, GET_INQUIRY_EXPORT_SUMMARY_DATA_SUCCESS, GET_INQUIRY_IMPORT_SUMMARY_DATA, GET_INQUIRY_IMPORT_SUMMARY__DATA_SUCCESS, GET_INQUIRY_SALES_CUSTOMER_SUMMARY_DATA, GET_INQUIRY_SALES_CUSTOMER_SUMMARY_DATA_SUCCESS, GET_INQUIRY_SUMMARY_DATA, GET_INQUIRY_SUMMARY_DATA_SUCCESS, GET_QUOTATION_DATA, GET_QUOTATION_HISTORY_DATA_BY_ID, GET_QUOTATION_HISTORY_DATA_SUCCESS, GET_QUOTATION_RESULT_DATA, GET_QUOTATION_RESULT_DATA1, GET_QUOTATION_RESULT_DATA2, GET_QUOTATION_RESULT_DATA3, GET_QUOTATION_RESULT_FAIL, GET_QUOTATION_RESULT_SUCCESS } from "./actiontype";
import { getExchangeRate, getSalesInquiryTableData, getSalesQuotTableData, getSearchResultData, getSearchResultData1, getSearchResultData2, getSearchResultData3 } from "../../helpers/fakebackend_helper";
import { getInquirySummeryData, getSalesInquiryData, getSalesQuotationDataFail, getSalesQuotationDataSuccess, getTenantQuotationSummery } from "./actions";
import { GET_CURRENCY_EXCHANGE_RATE, GET_CURRENCY_EXCHANGE_RATE_SUCCESS, GET_TENANT_FCL_QUOTATION_SUMMERY, GET_TENANT_FCL_QUOTATION_SUMMERY_BY_ID, GET_TENANT_FCL_QUOTATION_SUMMERY_SUCCESS, POST_TENANT_FCL_QUOTATION_SUMMERY, POST_TRANSFER_STATUS, POST_TRANSFER_STATUS_BY_ID, POST_TRANSFER_STATUS_IS_LODING, POST_TRANSFER_STATUS_IS_SUCCESS_LODING, UPDATE_QUOTION_ASSIGN_TO, UPDATE_QUOTION_STATUS } from "./Quotation/actiontype";
import { getInquiryCustomerSummary, getInquiryExportSummary, getInquiryImportSummary, getInquirySalesCustomerSummary, getInquirySummary } from "../../helpers/services/InstantRateService";
import { getDashBoardQuotionSummerySer, getQuotationHistoryByIdSer, getQuotationSummeryByIdSer, getQuotationSummerySer, postQuotationSummerySer, postTransferStatusSer, postTransferStatusSummerySer, postUpdateQuotionAssingToSer, postUpdateQuotionStatusSer } from "../../helpers/services/QuotationService";
import { showErrorToast, showSuccessToast } from "../../components/Common/CustomToast";
import { QUOTATION_RESULT_SELECTED, QUOTATION_RESULT_SELECTED_SUCCESS, QUOTATION_RESULT_SELECTED_TENANT } from "../InstantRate/actionType";
import { IN_PROGRESS, INQUIRY_REJECTED, REQUEST_FOR_RATE, taskStatus } from "../../pages/Dashboard/Partials/initialData";
function* fetchSalesQuotationData() {
    try {
        const response = yield call(getSalesQuotTableData);
        yield put(getSalesQuotationDataSuccess(response));
    } catch (error) {
        yield put(getSalesQuotationDataFail(error));
    }
}
function* fetchCurrencyExchangeData() {
    try {
        const response = yield call(getExchangeRate);
        yield put({ type: GET_CURRENCY_EXCHANGE_RATE_SUCCESS, payload: response })
    } catch (error) {
        console.log(error, "error")
    }
}

function* fetchSalesInquiryData({ payload }) {
    yield put({ type: GET_INQUIRY_DATA_LOADER, payload: true })
    try {
        const response = yield call(getSalesInquiryTableData, payload);
        yield put({ type: GET_INQUIRY_DATA_SUCCESS, payload: response });
        yield put({ type: GET_INQUIRY_DATA_LOADER, payload: false })
    } catch (error) {
        yield put({ type: GET_INQUIRY_DATA_FAIL, payload: error });
        yield put({ type: GET_INQUIRY_DATA_LOADER, payload: false })
    }
}

function* fetchInquiryCustomerSummery() {
    try {
        const response = yield call(getInquiryCustomerSummary);
        yield put({ type: GET_INQUIRY_CUSTOMER_SUMMARY_DATA_SUCCESS, payload: response });
    } catch (error) {
        console.log(error, "error")
    }
}

function* fetchInquirySalesCustomerSummery() {
    try {
        const response = yield call(getInquirySalesCustomerSummary);
        yield put({ type: GET_INQUIRY_SALES_CUSTOMER_SUMMARY_DATA_SUCCESS, payload: response });
    } catch (error) {
        console.log(error, "error")
    }
}
function* fetchInquirySummery() {
    try {
        const response = yield call(getInquirySummary);
        yield put({ type: GET_INQUIRY_SUMMARY_DATA_SUCCESS, payload: response });
    } catch (error) {
        console.log(error, "error")
    }
}

function* fetchInquiryImportSummery() {
    try {
        const response = yield call(getInquiryImportSummary);
        yield put({ type: GET_INQUIRY_IMPORT_SUMMARY__DATA_SUCCESS, payload: response });
    } catch (error) {
        console.log(error, "error")
    }
}

function* fetchInquiryExportSummery() {
    try {
        const response = yield call(getInquiryExportSummary);
        yield put({ type: GET_INQUIRY_EXPORT_SUMMARY_DATA_SUCCESS, payload: response });
    } catch (error) {
        console.log(error, "error")
    }
}


function* getQuotationSummerySaga({ payload: { data } }) {
    yield put({ type: GET_INQUIRY_DATA_LOADER, payload: true })
    try {
        const response = yield call(getQuotationSummerySer, data);
        yield put({ type: GET_TENANT_FCL_QUOTATION_SUMMERY_SUCCESS, payload: response })
        yield put({ type: GET_INQUIRY_DATA_LOADER, payload: false })

    } catch (error) {
        yield put({ type: GET_INQUIRY_DATA_LOADER, payload: false })

        console.log("error", error);
    }
}

function* postTransferStatusSaga({ payload: { data } }) {
    try {
        yield put({ type: POST_TRANSFER_STATUS_IS_LODING, payload: true })
        const response = yield call(postTransferStatusSer, data?.quotationId, data?.statusId, data?.reasonId);
        showSuccessToast("Update Successfully");
        yield put(getTenantQuotationSummery(""))
        yield put({ type: POST_TRANSFER_STATUS_IS_LODING, payload: false })
        yield put({ type: POST_TRANSFER_STATUS_IS_SUCCESS_LODING, payload: response })
    } catch (error) {
        yield put({ type: POST_TRANSFER_STATUS_IS_LODING, payload: false })
        showErrorToast(error?.response?.data?.message);
    }
}

function* updateQuotionStatusSaga({ payload: { data } }) {
    try {
        const response = yield call(postUpdateQuotionStatusSer, data);
        let url = `?page=${0}&size=10&requestForRates=true&statusCode=${REQUEST_FOR_RATE},${IN_PROGRESS}`;
        yield put(getSalesInquiryData(url));
        showSuccessToast("Request for rates Updated Successfully");
    } catch (error) {
        showErrorToast(error?.response?.data?.message);
    }
}

function* updateQuotionAssingToSaga({ payload: { data } }) {
    try {
        const response = yield call(postUpdateQuotionAssingToSer, data);
        showSuccessToast("Updated Successfully");
        if (data?.inquiryIds) {
            let url = `?page=${0}&size=10&requestForRates=true&statusCode=${REQUEST_FOR_RATE},${IN_PROGRESS}`;
            yield put(getSalesInquiryData(url));
        } else if (data?.quotationsIds) {
            yield put(getTenantQuotationSummery(""))
        }
    } catch (error) {
        showErrorToast(error?.response?.data?.message);
    }
}

function* postQuotationSummerySaga({ payload: { data } }) {
    try {
        const response = yield call(postQuotationSummerySer, data);
        console.log(response);
        
        yield put({ type: QUOTATION_RESULT_SELECTED_TENANT, payload: response })
        showSuccessToast("Updated Successfully");
        if (data?.id) {
            yield put(getTenantQuotationSummery(""))
        }
    } catch (error) {
        showErrorToast(error?.response?.data?.message);
    }
}

function* getQuotationSummeryByIdSaga({ payload: { data } }) {
    yield put({ type: QUOTATION_RESULT_SELECTED_SUCCESS, payload: true })

    try {
        const response = yield call(getQuotationSummeryByIdSer, data);
        response?.fclInquiryResults?.forEach(data => {
            data.tariffDetails.forEach(tariffdatails => {
                tariffdatails.selected = true;
            })
        });
        yield put({ type: QUOTATION_RESULT_SELECTED, payload: response?.fclInquiryResults })
        yield put({ type: QUOTATION_RESULT_SELECTED_TENANT, payload: response?.tenantFclQuotationSummary })

    } catch (error) {
        showErrorToast(error?.response?.data?.message);
    }
    yield put({ type: QUOTATION_RESULT_SELECTED_SUCCESS, payload: false })

}

function* getTenantQuotionSummerySaga({ payload: { data } }) {
    try {
        const response = yield call(getDashBoardQuotionSummerySer, data);
        taskStatus.revenueSum[0].items[0].revenue = response?.totalRevenue || 0
        taskStatus.revenueSum[0].items[0].rate = response?.totalRevenuePercentage || 0
        taskStatus.revenueSum[0].items[1].revenue = response?.projectedTotalRevenue || 0
        taskStatus.revenueSum[0].items[1].rate = response?.projectedTotalRevenuePercentage || 0
        taskStatus.revenueSum[2].items[0].revenue = response?.activePortPairs || 0
        taskStatus.revenueSum[2].items[1].revenue = "-"
        taskStatus.revenueSum[2].items[2].revenue = response?.conversionRation || 0
        response.taskStatus = taskStatus;
        yield put({ type: GET_DASHBOARD_INQUIRY_SUMMARY_DATA_SUCCESS, payload: response })
    } catch (error) {
        console.log("error", error);
    }
}


function* getQuotationHistoryByIdSaga({ payload: { data } }) {
    try {
        const response = yield call(getQuotationHistoryByIdSer, data);
        yield put({ type: GET_QUOTATION_HISTORY_DATA_SUCCESS, payload: response });
    } catch (error) {
        showErrorToast(error?.response?.data?.message);
    }
}

export function* watchGetSalesData() {
    yield takeEvery(GET_QUOTATION_DATA, fetchSalesQuotationData);
    yield takeEvery(GET_CURRENCY_EXCHANGE_RATE, fetchCurrencyExchangeData);
    yield takeEvery(GET_INQUIRY_DATA, fetchSalesInquiryData);
    yield takeEvery(GET_INQUIRY_CUSTOMER_SUMMARY_DATA, fetchInquiryCustomerSummery);
    yield takeEvery(GET_INQUIRY_SALES_CUSTOMER_SUMMARY_DATA, fetchInquirySalesCustomerSummery);
    yield takeEvery(GET_INQUIRY_SUMMARY_DATA, fetchInquirySummery);
    yield takeEvery(GET_INQUIRY_IMPORT_SUMMARY_DATA, fetchInquiryImportSummery);
    yield takeEvery(GET_INQUIRY_EXPORT_SUMMARY_DATA, fetchInquiryExportSummery);
    yield takeEvery(POST_TENANT_FCL_QUOTATION_SUMMERY, postQuotationSummerySaga);
    yield takeEvery(POST_TRANSFER_STATUS, postTransferStatusSaga);
    yield takeEvery(UPDATE_QUOTION_STATUS, updateQuotionStatusSaga);
    yield takeEvery(UPDATE_QUOTION_ASSIGN_TO, updateQuotionAssingToSaga);
    yield takeEvery(GET_TENANT_FCL_QUOTATION_SUMMERY, getQuotationSummerySaga);
    yield takeEvery(GET_TENANT_FCL_QUOTATION_SUMMERY_BY_ID, getQuotationSummeryByIdSaga);
    yield takeEvery(GET_DASHBOARD_INQUIRY_SUMMARY_DATA, getTenantQuotionSummerySaga);
    yield takeEvery(GET_QUOTATION_HISTORY_DATA_BY_ID, getQuotationHistoryByIdSaga);

}

function* salesSaga() {
    yield all([fork(watchGetSalesData)]);
}

export default salesSaga;