// ----------------user details -----------
export const GET_USERS_TABLE_DATA = 'GET_USERS_TABLE_DATA';
export const GET_USERS_TABLE_DATA_SUCCESS = 'GET_USERS_TABLE_DATA_SUCCESS';
export const GET_USERS_TABLE_DATA_FAIL = 'GET_USERS_TABLE_DATA_FAIL';
export const UPDATE_USER_SWITCH = 'UPDATE_USER_SWITCH';
export const GET_USERS_LOADER_TYPE = 'GET_USERS_LOADER_TYPE';


// add-users-in settings
export const GET_COMPANY_ADD_USERS_DATA = 'GET_COMPANY_ADD_USERS_DATA';
export const GET_COMPANY_ADD_USERS_DATA_SUCCESS = 'GET_COMPANY_ADD_USERS_DATA_SUCCESS';
export const GET_COMPANY_ADD_USERS_DATA_FAIL = 'GET_COMPANY_ADD_USERS_DATA_FAIL';

// --------------- company details -----------
// export const GET_COMPANYDETAILS_DATA = 'GET_COMPANYDETAILS_DATA';
// export const GET_COMPANYDETAILS_DATA_SUCCESS = 'GET_COMPANYDETAILS_DATA_SUCCESS';
// export const GET_COMPANYDETAILS_DATA_FAIL = 'GET_COMPANYDETAILS_DATA_FAIL';

// ----------------- surcharge fcl data ---------
export const GET_FCL_SURCHARGE_TABLE_DATA = 'GET_FCL_SURCHARGE_TABLE_DATA';
export const GET_FCL_SURCHARGE_TABLE_DATA_SUCCESS = 'GET_FCL_SURCHARGE_TABLE_DATA_SUCCESS';
export const GET_FCL_SURCHARGE_TABLE_DATA_FAIL = 'GET_FCL_SURCHARGE_TABLE_DATA_FAIL';


// original api

export const GET_COMPANYDETAILS_BASIC_DATA = 'GET_COMPANYDETAILS_BASIC_DATA';
export const GET_COMPANYDETAILS_BASIC_DATA_SUCCESS = 'GET_COMPANYDETAILS_BASIC_DATA_SUCCESS';
export const GET_COMPANYDETAILS_BASIC_DATA_FAIL = 'GET_COMPANYDETAILS_BASIC_DATA_FAIL';

export const GET_COMPANY_CITY_DATA = "GET_COMPANY_CITY_DATA"
export const GET_COMPANY_CITY_DATA_SUCCESS = "GET_COMPANY_CITY_DATA_SUCCESS"
export const GET_COMPANY_CITY_DATA_FAIL = "GET_COMPANY_CITY_DATA_FAIL"

export const GET_COMPANY_STATE_DATA = "GET_COMPANY_STATE_DATA"
export const GET_COMPANY_STATE_DATA_SUCCESS = "GET_COMPANY_STATE_DATA_SUCCESS"
export const GET_COMPANY_STATE_DATA_FAIL = "GET_COMPANY_STATE_DATA_FAIL"

export const GET_COMPANY_COUNTRY_DATA = "GET_COMPANY_COUNTRY_DATA"
export const GET_COMPANY_COUNTRY_DATA_SUCCESS = "GET_COMPANY_COUNTRY_DATA_SUCCESS"
export const GET_COMPANY_COUNTRY_DATA_FAIL = "GET_COMPANY_COUNTRY_DATA_FAIL"

export const GET_COMPANY_PINCODE_DATA = "GET_COMPANY_PINCODE_DATA"
export const GET_COMPANY_PINCODE_DATA_SUCCESS = "GET_COMPANY_PINCODE_DATA_SUCCESS"
export const GET_COMPANY_PINCODE_DATA_FAIL = "GET_COMPANY_PINCODE_DATA_FAIL"

// All Taxes
export const GET_TAXES_DATA = "GET_TAXES_DATA"
export const GET_TAXES_DATA_SUCCESS = "GET_TAXES_DATA_SUCCESS"
export const GET_TAXES_DATA_FAIL = "GET_TAXES_DATA_FAIL"
export const POST_MAIL_CONFIGURATION_DATA = "POST_MAIL_CONFIGURATION_DATA"
// All Business data

export const GET_BUSINESS_DATA = "GET_BUSINESS_DATA"
export const GET_BUSINESS_DATA_SUCCESS = "GET_BUSINESS_DATA_SUCCESS"
export const GET_BUSINESS_DATA_FAIL = "GET_BUSINESS_DATA_FAIL"

// All get Request data
export const GET_ALL_COMPANY_SETTINGS = "GET_ALL_COMPANY_SETTINGS"
export const GET_ALL_COMPANY_SETTINGS_SUCCESS = "GET_ALL_COMPANY_SETTINGS_SUCCESS"
export const GET_ALL_COMPANY_SETTINGS_FAIL = "GET_ALL_COMPANY_SETTINGS_FAIL"

// all get surcharge listing table
export const GET_PARTIES_SURCHARGE_TABLE = "GET_PARTIES_SURCHARGE_TABLE"
export const GET_PARTIES_SURCHARGE_TABLE_SUCCESS = "GET_PARTIES_SURCHARGE_TABLE_SUCCESS"
export const GET_PARTIES_SURCHARGE_TABLE_FAIL = "GET_PARTIES_SURCHARGE_TABLE_FAIL"

// post data in surcharge data
export const POST_M_SURCHARGE_DATA = "POST_M_SURCHARGE_DATA"
export const POST_SETTINGS_SURCHARGE_DATA_SUCCESS = "POST_SETTINGS_SURCHARGE_DATA_SUCCESS"
export const POST_SETTINGS_SURCHARGE_DATA_FAIL = "POST_SETTINGS_SURCHARGE_DATA_FAIL"

// all get category of surcharge

export const GET_ALL_SURCHARGE_CATEGORY = "GET_ALL_SURCHARGE_CATEGORY"
export const GET_ALL_SURCHARGE_CATEGORY_SUCCESS = "GET_ALL_SURCHARGE_CATEGORY_SUCCESS"
export const GET_ALL_SURCHARGE_CATEGORY_FAIL = "GET_ALL_SURCHARGE_CATEGORY_FAIL"


// ALL TENENT LOCATION
export const GET_ALL_TENANT_LOCATION = "GET_ALL_TENENT_LOCATION";
export const GET_ALL_TENANT_LOCATION_SUCCESS = "GET_ALL_TENANT_LOCATION_SUCCESS";



// SAVE TENANT_LOCATION
export const POST_TENANT_LOCATION = "POST_TENANT_LOCATION";
export const POST_TENANT_LOCATION_SUCCESS = "POST_TENANT_LOCATION_SUCCESS";

// GET ALL TENANT LOCATION TYPE
export const GET_ALL_TENANT_LOCATION_TYPE = "GET_ALL_TENANT_LOCATION_TYPE";
export const GET_ALL_TENANT_LOCATION_TYPE_SUCCESS = "GET_ALL_TENANT_LOCATION_TYPE_SUCCESS";

// SAVE TENANT_LOCATION_TYPE
export const POST_TENANT_LOCATION_TYPE = "POST_TENANT_LOCATION_TYPE";
export const POST_TENANT_LOCATION_TYPE_SUCCESS = "POST_TENANT_LOCATION_TYPE_SUCCESS";

