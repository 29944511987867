import "nouislider/distribute/nouislider.css";
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Select from "react-select";
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import { optionDestQuote, optionOriginQuote } from '../../../common/data/sales';
import { getAllUserDetails } from "../../../store/Parties/actions";
import { useDispatch } from "react-redux";
import { getAllPartiesCustomerData } from "../../../store/Parties/Customer/action";

export default function FilterSalesInquiryComp({ isRight, toggleRightCanvas, filterDetails, setfilterDetails, applyFilterHandler, clearValueHandler }) {
    const { status_master, oceanPort_data } = useSelector((state) => state.globalReducer);
    const { customer_data } = useSelector((state) => state?.customer);
    const { parties_all_employee_details } = useSelector((state) => state?.parties);
    const dispatch = useDispatch();

    const customerOptions = customer_data?.content?.map(customer => ({
        value: customer.id,
        label: customer?.name,
        email: customer.contactEmail,
        version: customer.version,
        id: customer.id,
    })) || [];

    const salesEmployeeOptions = parties_all_employee_details?.content?.map((item) => ({
        value: item?.firstName,
        label: item?.firstName + " " + item?.lastName,
        id: item?.id,
        version: item?.version
    }));

    const handleSelectGroup = useCallback((name, opt) => {
        let newObj = {
            ...filterDetails,
            [name]: opt
        }
        setfilterDetails(newObj);
    }, [filterDetails]);

    useEffect(() => {
        dispatch(getAllUserDetails())
        dispatch(getAllPartiesCustomerData(""))
    }, [dispatch]);

    return (
        <>
            <Offcanvas
                isOpen={isRight}
                direction="end"
                toggle={toggleRightCanvas}>
                <OffcanvasHeader toggle={toggleRightCanvas}>
                    Filter
                </OffcanvasHeader>
                <OffcanvasBody>
                    <form className='h-100'>
                        <div className="fcl_filter_sidebar_wrap sales_filter_wrap d-flex flex-column h-100">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor='valid_from' >Inquiry From</label>
                                        <input type="date" name="valid_from" id="valid_from" className='form-control' value={filterDetails.valid_from} onChange={(e) => handleSelectGroup('valid_from', e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor='valid_to' >Inquiry To</label>
                                        <input type="date" name="valid_to" id="valid_to" className='form-control' value={filterDetails.valid_to} onChange={(e) => handleSelectGroup('valid_to', e.target.value)} />
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label >Origin</label>
                                        <Select
                                            value={filterDetails.org_port}
                                            name='org_port'
                                            onChange={(opt) => { handleSelectGroup('org_port', opt) }}
                                            options={oceanPort_data}
                                            placeholder={'Select Origin'}
                                            classNamePrefix="select2-selection form-select"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label >Destination</label>
                                        <Select
                                            value={filterDetails.dest_port}
                                            name='dest_port'
                                            onChange={(opt) => { handleSelectGroup('dest_port', opt) }}
                                            options={oceanPort_data}
                                            placeholder={'Select Destination'}
                                            classNamePrefix="select2-selection form-select"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label>Customer</label>
                                        <Select
                                            value={filterDetails.customer}
                                            name='customer'
                                            onChange={(opt) => { handleSelectGroup('customer', opt) }}
                                            options={customerOptions}
                                            placeholder={'Select Status'}
                                            classNamePrefix="select2-selection form-select"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label>Sales Employee</label>
                                        <Select
                                            value={filterDetails.sales_employee}
                                            name='sales_employee'
                                            onChange={(opt) => {
                                                handleSelectGroup('sales_employee', opt);
                                            }}
                                            options={salesEmployeeOptions}
                                            placeholder={'Select Status'}
                                            classNamePrefix="select2-selection form-select"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label>Status</label>
                                        <Select
                                            value={filterDetails.status}
                                            name='status'
                                            onChange={(opt) => {
                                                handleSelectGroup('status', opt);
                                            }}
                                            options={status_master}
                                            placeholder={'Select Status'}
                                            classNamePrefix="select2-selection form-select"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="btn_wrap d-flex mt-auto">
                                <button className='btn border' type='button' onClick={() => { clearValueHandler(); }}>Clear</button>
                                <button className='btn btn-primary' type='button' onClick={applyFilterHandler}>Apply Filter</button>
                            </div>
                        </div>
                    </form>
                </OffcanvasBody>
            </Offcanvas>
        </>
    )
}
